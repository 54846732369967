import React from "react";
import { Navigate } from "react-router-dom";
import { localStorage } from "../../Security";

function AuthRoute({ children }) {
  let adminCredentials = localStorage.get("adminCredentials");
  if (adminCredentials === "" || adminCredentials == null) {
    return <Navigate to="/sign-in" />;
  }
  //Signed in
  return children;
}

export default AuthRoute;
